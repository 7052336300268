// App.js
import React, { useEffect, useState } from "react";
import "./App.css";
import Menu from "./components/Menu";
import About from "./components/About";
import Point from "./components/Point";
import Approach from "./components/Approach";
import Product from "./components/Product";
import Benefit from "./components/Benefit";
import Contact from "./components/Contact";
function App() {
  const [isMobile, setIsMobile] = useState(false);

  function checkIsMobile() {
    const isMobileWidth = window.matchMedia("(max-width: 768px)").matches;
    const isIpadLandscape = window.matchMedia(
      "(orientation: landscape)"
    ).matches;
    const isIpadPortrait = window.matchMedia("(orientation: portrait)").matches;
    const isDesktopOrAppleLaptop =
      window.matchMedia("(min-width: 1030px)").matches ||
      (navigator.userAgent.includes("Macintosh") &&
        navigator.maxTouchPoints > 1);

    setIsMobile(
      (isMobileWidth || isIpadLandscape || isIpadPortrait) &&
        !isDesktopOrAppleLaptop
    );
  }

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={isMobile ? "col-12" : "col-lg-1"}>
          <Menu />
        </div>
        <div className={isMobile ? "col-lg-12 p-0" : "col-lg-11 p-0"}>
          <main className="main-content">
            <About />
            <Point />
            <Approach />
            <Product />
            <Benefit />
            <Contact />
          </main>
        </div>
      </div>
    </div>
  );
}
export default App;
